import {
  LW,
  StyledEventsLink,
  Wrap,
} from 'CORE__UI/navigation/TopLink/core__topLinkStyles';
import styled, { css } from 'styled-components';
import { BRAND, GREYS, FONT } from '../globals/colours';

const StyledEventsLink_EX = styled(StyledEventsLink)`
  font-weight: 800;
  text-align: center;
  font-size: 14px;
  line-height: 20px;
  padding: 8px 0;
  margin-top: 4px;
  margin-bottom: 8px;
  margin-left: 16px;
  margin-right: 0;
  &:hover {
    color: ${({ theme }) => (theme.dark ? GREYS.white : GREYS.black)};
    font-weight: 800;
  }
  font-family: source sans pro, sans-serif;
  ${({ active }) =>
    active &&
    css`
      color: ${({ casinoTheme }) =>
        casinoTheme ? FONT.dark : FONT.black.standard};
      border-color: ${({ casinoTheme }) =>
        casinoTheme ? BRAND.secondary : BRAND.secondary};
      &:hover {
        color: ${({ casinoTheme }) =>
          casinoTheme ? FONT.dark : FONT.black.standard};
        font-weight: 800;
      }
    `}
`;

const Wrap_EX = styled(Wrap)`
  margin-top: 4px;
`;

export { LW, StyledEventsLink_EX as StyledEventsLink, Wrap_EX as Wrap };

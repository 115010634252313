import React, { useEffect, useState, useMemo } from 'react';
import PropTypes from 'prop-types';
import PubSub from 'Services/pubsub/core__pubsub';
import { PubsubEvents } from 'Services/pubsub/core__pubsub.constants';
import { HeaderTabsComponent } from './HeaderTabs';
import { Wrap } from 'UI/navigation/TopLinkStyles';
import { FSBTheme } from 'Services/core__fsb-theme';
import { FSBCustomerSession } from 'Services/core__services';

export const EventsHeaderReact = ({ appConfig }) => {
  const theme = appConfig?.theme;
  const [headers, setHeaders] = useState(() =>
    __getHeadersFromConfig(appConfig)
  );
  const [activeHeader, setActiveHeader] = useState(() =>
    __getActiveHeader(headers)
  );
  const [hideHeaders, setHideHeaders] = useState(false);
  const [loggedIn, setLoggedIn] = useState(false);
  const showRegionSelection = appConfig?.showregionselection;
  const showHideBasedOnAuth = useMemo(
    () => appConfig?.showhidebasedonauth?.split(',') || [],
    [appConfig]
  );

  // Filter headers based on auth status
  const filteredHeaders = useMemo(() => {
    if (!loggedIn) {
      return Object.keys(headers).reduce((acc, key) => {
        if (!showHideBasedOnAuth.includes(headers[key])) {
          acc[key] = headers[key];
        }
        return acc;
      }, {});
    }
    return headers;
  }, [headers, loggedIn, showHideBasedOnAuth]);

  useEffect(() => {
    const updateSession = () => {
      const session = FSBCustomerSession.getSession();
      setLoggedIn(!!session?.accessToken);
    };

    // Listen for session updates
    const sessionUpdated = PubSub.listen(
      PubsubEvents.SESSION_UPDATED,
      updateSession
    );
    const sessionCreated = PubSub.listen(
      PubsubEvents.SESSION_CREATED,
      updateSession
    );

    // Initial session check
    updateSession();

    return () => {
      sessionUpdated?.unsubscribe();
      sessionCreated?.unsubscribe();
    };
  }, []);

  useEffect(() => {
    /**
     * set headers based on events app emit recieved
     */
    const eventHeaderUpdateListener = PubSub.listen(
      PubsubEvents.EventHeaderUpdate,
      wsData => {
        const headersToSet = { ...headers };

        if (wsData.appid && !wsData.hide) {
          //stirng appended to preserver order in which it is recieved
          headersToSet[wsData.appid + '_id'] = wsData.title;
        } else if (wsData.appid && wsData.hide) {
          headersToSet[wsData.appid + '_id'] = null;
        }
        //if no events available, do not hide header tab items
        if (!showRegionSelection) {
          setHeaders(headersToSet);
        }
      }
    );
    const hideEventHeadersListener = PubSub.listen(
      PubsubEvents.hideEventHeaders,
      wsData => {
        if (wsData.hide) {
          setHideHeaders(true);
        }
      }
    );

    return () => {
      eventHeaderUpdateListener?.unsubscribe();
      hideEventHeadersListener?.unsubscribe();
    };
  }, [setHeaders, headers, hideHeaders, setHideHeaders, showRegionSelection]);

  //checks if active tab is stored in local storage
  useEffect(() => {
    if (appConfig?.saveselectedtab) {
      const lastActiveTab = window.localStorage.getItem('lastActiveTab');
      if (lastActiveTab) {
        const tabKeys = Object.keys(headers);
        tabKeys.includes(lastActiveTab) && setActiveHeader(lastActiveTab);
      }
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    let timeout;
    if (!activeHeader || !headers[activeHeader]) {
      timeout = setTimeout(() => {
        const activeHeader = __getActiveHeader(headers);
        if (activeHeader === 0) {
          PubSub.emit(PubsubEvents.DisplayNotFound, true);
        }
        setActiveHeader(activeHeader);
      }, 100);
    }

    return () => {
      if (timeout) clearTimeout(timeout);
    };
  }, [headers, activeHeader]);
  useEffect(() => {
    PubSub.emit(PubsubEvents.EventsAppActive, {
      appid: parseInt(activeHeader),
    });

    if (appConfig?.saveselectedtab) {
      window?.localStorage.setItem('lastActiveTab', activeHeader);
    }
  }, [activeHeader]);

  return (
    <FSBTheme theme={theme}>
      <Wrap customStyle={appConfig?.customstyle}>
        {!hideHeaders && (
          <HeaderTabsComponent
            casinoTheme={appConfig?.casinotheme}
            promotionTheme={appConfig?.promotiontheme}
            headers={filteredHeaders}
            activeHeader={activeHeader}
            setActiveHeader={setActiveHeader}
            appConfig={appConfig}
            loggedIn={loggedIn}
          />
        )}
      </Wrap>
    </FSBTheme>
  );
};

EventsHeaderReact.propTypes = {
  appConfig: PropTypes.object,
  customStyle: PropTypes.bool,
  casinoTheme: PropTypes.bool,
  promotionTheme: PropTypes.bool,
};
/**private functions */
function __getHeadersFromConfig(appConfig) {
  const result = {};
  if (appConfig && appConfig.apps) {
    return appConfig.apps;
  }
  return result;
}

function __getActiveHeader(headers) {
  const appIds = Object.keys(headers);
  let firstHeader = 0;
  let i = 0;
  while (i < appIds.length) {
    if (headers[appIds[i]] && firstHeader === 0) {
      firstHeader = appIds[i];
      i = 1000; //break
    }
    i++;
  }
  return firstHeader;
}
